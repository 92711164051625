"use client";
import Link from "next/link";
// import { useTranslations } from "next-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import useLanguageTranslations from "@/hooks/useLanguageTranslations";
import Loader from "../Loader/Loader";
export default function Intro({ params }) {
  // console.log(params);
  // return;
  // const translated = useTranslations("home");
  const translated = useLanguageTranslations(params.locale, "home");
  if (!translated) {
    return <Loader />;
  }
  return (
    <div
      className="section video-section"
      alt="skilrock employees standing at their exhibition booth in ice london. "
    >
      <div className="container">
        <div className="video-wrap">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div className="video-content text-center">
                <h2 className="title">
                  {translated("STR_PROVIDE_WORLD_CLASS_SOFTWARE")}
                </h2>
                <div className="video-play">
                  <Link
                    className="popup-video-home"
                    href="https://www.youtube.com/watch?v=VEEagMpTbKk"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faPlay} />
                    <span>{translated("STR_WATCH_INTRO")}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

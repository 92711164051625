"use client";
import React from "react";
import Image from "next/image";
import Link from "next/link";
import { HomeData as HomeOmniChannelData } from "@/data/home";
import { useTranslations } from "next-intl";
import useLanguageTranslations from "@/hooks/useLanguageTranslations";
import Loader from "../Loader/Loader";
const OmniChannel = ({params}) => {
  const omniSectionData = HomeOmniChannelData.homeOmniChannel;
  const translated = useLanguageTranslations(params.locale, "home");
  if(!translated){
    return <Loader/>
  }
  return (
    <div className="section overview-section-2 section-padding">
      <div className="container">
        <div className="overview-wrap">
          <div className="row">
            <div className="col-lg-6">
              <div className="overview-content">
                <div className="section-title2">
                  <h2 className="title">
                    {translated(omniSectionData.str_true)}{" "}
                    <span>{translated(omniSectionData.str_omniChannel)}</span>{" "}
                    {translated(omniSectionData.str_plateform)}
                  </h2>
                </div>
                <p className="text">
                  {translated(omniSectionData.str_omniChannel_desc)}
                </p>
                <Link
                  className="btn btn-2 my-4"
                  href="lottery-gaming-solutions"
                  aria-label="lottery-gaming-solutions"
                >
                  {translated(omniSectionData.str_knowMore)}
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="overview-img">
                <div className="shape">
                  <Image
                    src={omniSectionData.imageShape}
                    alt={omniSectionData.imageShapeAlt}
                    loading="lazy"
                    className="img-fluid"
                  />
                </div>
                <div className="image">
                  <Image
                    src={omniSectionData.imageOverView}
                    alt={omniSectionData.imageOverViewAlt}
                    loading="lazy"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OmniChannel;

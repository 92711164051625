"use client";
import React from "react";
import { HomeData as HomeOurPresence } from "@/data/home";
import { useTranslations } from "next-intl";
import useLanguageTranslations from "@/hooks/useLanguageTranslations";
import Loader from "../Loader/Loader";
const Presence = ({params}) => {
  const homeOurPresence = HomeOurPresence.homeOurPresence;
  const translated = useLanguageTranslations(params.locale, "home");
  if(!translated){
    return <Loader/>
  }
  return (
    <div className="section features-section-2 map-section section-padding-03 ourPresenceBg">
      <div className="container">
        <div className="features-wrap-custom-1">
          <div className="section-title2 text-center">
            <h2 className="title">
              {translated(homeOurPresence.str_our)}{" "}
              <span>{translated(homeOurPresence.str_presence)} </span>
            </h2>
            <p>{translated(homeOurPresence.str_presence_desc)}</p>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <video
                width="100%"
                height="100%"
                autoPlay
                muted
                loop
                loading="lazy"
              >
                <source src="/assets/media/videos/map.webm" type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Presence;

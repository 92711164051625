"use client";
import Link from "next/link";
import Image from "next/image";
/**Swiper */
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { home_upcoming as homeUpcomingData } from "@/data/homeUpcoming";
import { useState } from "react";
import Modal from "@/components/Modal/Modal";
import BookMeetingForm from "@/components/Form/BookMeetingForm";
import useLanguageTranslations from "@/hooks/useLanguageTranslations";
import Loader from "../Loader/Loader";
export default function Upcoming({params}) {
  const translated = useLanguageTranslations(params.locale, "home");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const cdate = new Date();
  const showModel = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);
  if(!translated){
    return <Loader/>
  }
  return (
    <>
      {isModalOpen && <Modal show={isModalOpen} onClose={closeModal}><BookMeetingForm fullWidth={true} lang={params.locale}/></Modal>}
      <div
        className="section case-study-section-2 section-padding event-backgound-image"
        alt="silhouttes of a skyline of renowned monuments from across the world in a row at bottom"
      >
        <div className="container">
          <div className="case-study-wrap">
            <div className="section-title text-center">
              <h3 className="sub-title">{translated("STR_UPCOMING_EVENTS")}</h3>
              <h2 className="title discover">
                {translated("STR_UPCOMING_DESC")}
              </h2>
            </div>
            {homeUpcomingData.book_meeting.length > 0 && (
              <div className="case-study-content-wrap">
                <div className="row">
                  {homeUpcomingData.book_meeting.map((bookMeet, index) => {
                    const fromdate = new Date(bookMeet.fromdate);
                    const todate = new Date(bookMeet.todate);
                    if (cdate >= fromdate && cdate <= todate)
                      return (
                        <div className="col-lg-4 col-md-6" key={index}>
                          <div className="case-study-item">
                            <div className="case-study-img">
                              <Image
                                src={bookMeet.image}
                                alt={bookMeet.image_alt}
                                loading="lazy"
                                onClick={showModel}
                                className="img-fluid common_cursor"
                              />
                            </div>
                            <div className="case-study-content text-center">
                              <h3 className="title common_cursor">
                                {bookMeet.heading}
                              </h3>
                              <span>
                                <b>{bookMeet.event_date}</b>
                              </span>
                              <span className="vertical">|</span>
                              <span>{bookMeet.event_city}</span>
                              <br />
                              <center>
                                <span className="meet-btn">
                                  <button
                                    className="btn btn-2"
                                    onClick={showModel}
                                  >
                                    {translated(
                                      homeUpcomingData.bookMeetingButtonText
                                    )}
                                  </button>
                                </span>
                              </center>
                            </div>
                          </div>
                        </div>
                      );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="section Brand-section ">
        <div className="container">
          <div className="section-title2 text-center">
            <h2 className="title">
              {translated("STR_ASSOCIATION_CERTIFICATION")}
            </h2>
          </div>
          {homeUpcomingData.brand_certification.length > 0 && (
            <div className="brand-wrapper text-center">
              <div className="brand-active home-brand-active">
                <Swiper
                  spaceBetween={70}
                  slidesPerView={1}
                  loop={true}
                  mousewheel={false}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  className="upComing-HomeClass"
                >
                  {homeUpcomingData.brand_certification.map(
                    (bran_image, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="container">
                            <div className="row">
                              <div className="col-md-3 col-lg-3 col-sm-12  d-flex align-items-center brand-justify-image">
                                <Image
                                  src={bran_image.image}
                                  alt={bran_image.image_alt}
                                  loading="lazy"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="col-md-3 col-lg-3 col-sm-12 d-flex align-items-center brand-justify-image">
                                <Image
                                  src={bran_image.image2}
                                  alt={bran_image.image_alt2}
                                  loading="lazy"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="col-md-3 col-lg-3 col-sm-12 d-flex align-items-center brand-justify-image">
                                <Image
                                  src={bran_image.image3}
                                  alt={bran_image.image_alt3}
                                  loading="lazy"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="col-md-3 col-lg-3 col-sm-12 d-flex align-items-center brand-justify-image">
                                {bran_image.href_link4 != "" ? (
                                  <Link
                                    href={bran_image.href_link4}
                                    target="_blank"
                                  >
                                    <Image
                                      src={bran_image.image4}
                                      alt={bran_image.image_alt4}
                                      loading="lazy"
                                      className="img-fluid"
                                    />
                                  </Link>
                                ) : (
                                  <Image
                                    src={bran_image.image4}
                                    alt={bran_image.image_alt4}
                                    loading="lazy"
                                    className="img-fluid"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    }
                  )}
                </Swiper>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

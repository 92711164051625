"use client";

import React from 'react';
import Image from "next/image";
import { HomeData as HomeLotteryIndustryData } from "@/data/home";
import useLanguageTranslations from "@/hooks/useLanguageTranslations";
import Loader from "../Loader/Loader";
const LotteryIndustry = ({params}) => {
  const lotteryIndustrySectionData = HomeLotteryIndustryData.homeLotteryIndustry;
  const translated = useLanguageTranslations(params.locale, "home");
  if(!translated){
    return <Loader/>
  }
  return (
    <div className="section features-section-7 section-padding">
      <div className="container">
        <div className="features-wrap">
          <div className="row">
            <div className="col-lg-6">
              <div className="features-img-wrap">
                <div className="features-img image-1">
                  <Image
                    className='img-fluid'
                    src={lotteryIndustrySectionData.image}
                    alt={lotteryIndustrySectionData.imageAlt}
                    loading="lazy"
                  />
                </div>
                <span></span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="features-content">
                <div className="section-title">
                  <h2 className="title">
                    {translated(lotteryIndustrySectionData.str_transform_the)}{" "}
                    <span>{translated(lotteryIndustrySectionData.str_lottery_industry)}</span>{" "}
                    {translated(lotteryIndustrySectionData.str_50_years)}
                  </h2>
                </div>
                <p className="text">
                  {translated(lotteryIndustrySectionData.str_skilrock_cutting_edge)}
                </p>
                <p className="text">
                  {translated(lotteryIndustrySectionData.str_infinite_serves)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default LotteryIndustry;
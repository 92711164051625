"use client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountUp from "react-countup";
import Image from "next/image";
import { HomeData } from "@/data/home";
import { useTranslations } from "next-intl";
import Link from "next/link";
import useLanguageTranslations from "@/hooks/useLanguageTranslations";
import Loader from "../Loader/Loader";
const Experience = ({params}) => {
  const translated = useLanguageTranslations(params.locale, "home");
  const experienceData = HomeData.experience;
  if(!translated){
    return <Loader/>
  }
  return (
    <div className="section counter-section counter-section-2 counter-section-3 section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="experience-content">
              <div className="experience">
                <h2 className="number">50+ </h2>
                <span>{translated("STR_YEARS_OF_EXP")}</span>
              </div>
              <div className="experience-text">
                <h3 className="title">
                  {translated("STR_ENABLING")}{" "}
                  <span>{translated("STR_LOTTERY")} </span>{" "}
                  {translated("STR_OPERATOR_REACH")}
                </h3>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="service-content">
              <ul className="service-list">
                <li className="list">
                  <Link href="/about">
                    {translated("STR_ABOUT_SKILROCK")}{" "}
                    <FontAwesomeIcon
                      icon={experienceData.rightArrow}
                      className="fas fa-long-arrow-alt-right"
                    />
                  </Link>
                </li>
                <li className="list">
                  <Link href="/downloads">
                    {translated("STR_DOWNLOADS")}
                    <FontAwesomeIcon
                      icon={experienceData.rightArrow}
                      className="fas fa-long-arrow-alt-right"
                    />
                  </Link>
                </li>
                <li className="list">
                  <Link href="/gallery">
                    {translated("STR_GALLERY")}
                    <FontAwesomeIcon
                      icon={experienceData.rightArrow}
                      className="fas fa-long-arrow-alt-right"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="counter-wrap">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="counter-item">
                <div className="counter-icon">
                  <Image
                    src={experienceData.transaction}
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="counter-text">
                  <span>
                    <CountUp end={20} duration={1.5} />{" "}
                    {translated("STR_M_PLUS")}
                  </span>
                  <p>{translated("STR_TRANS_PER_DAY")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="counter-item">
                <div className="counter-icon">
                  <Image
                    src={experienceData.gamePlayer}
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="counter-text">
                  <span>
                    <CountUp end={7} duration={1.5} />{" "}
                    {translated("STR_M_PLUS")}
                  </span>
                  <p> {translated("STR_IGAMING_PLAYERS")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="counter-item">
                <div className="counter-icon">
                  <Image
                    src={experienceData.juridiction}
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="counter-text">
                  <span>
                    <CountUp end={30} duration={1.5} />{" "}
                    {translated("STR_M_PLUS")}
                  </span>
                  <p>{translated("STR_JURISDICTION")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="counter-item">
                <div className="counter-icon">
                  <Image
                    src={experienceData.posTerminal}
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="counter-text">
                  <span>
                    <CountUp end={200} duration={1.5} /> M+
                  </span>
                  <p>{translated("STR_POS_TERMINAL")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience
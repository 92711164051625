import styles from './Modal.module.css';

const Modal = ({ show, onClose, children, width }) => {
  // if (!show) return null;

  return (
    <div className={`${styles.modalOverlay} ${show ? styles.show : ''}`} onClick={onClose}>
      <div className={styles.modalContent} style={{ width: width || '' }} onClick={(e) => e.stopPropagation()}>
        {children}
        <button className={styles.closeButton} onClick={onClose}>
          &times;
        </button>
      </div>
    </div>
  );
};

export default Modal;
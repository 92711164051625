import image1 from "@/../public/assets/images/homebanner/banner-01-new-graphic.webp";
import image2 from "@/../public/assets/images/homebanner/banner-02-new-graphic.webp";
import image3 from "@/../public/assets/images/homebanner/banner-03-new-graphic.webp";
import image4 from "@/../public/assets/images/homebanner/wls.webp";
const banner = {

    crouselClass1: "row",
    imageDivClass: "images",
    contentHeadingClass: "title",
    contentHeadingClass1: "sub-title",
    crouselArray: [
        {
            image: image4,
            titleContent: "",
            titleContent1: "",
            alt: "Group of POS lottery machines and mobile devices with a few lottery tickets flying and a man riding a racing horse",
            crouselClass: "hero-images sk3",
            contentDivClass: "hero-content",
            bannerKey: false,
        },
        {
            image: image1,
            titleContent: "STR_LOTTERY_SUPPLIER",
            titleContent1: "STR_OF_YEAR",
            alt: "Group of POS lottery machines and mobile devices with a few lottery tickets flying and a man riding a racing horse",
            crouselClass: "hero-images sk",
            contentDivClass: "hero-content",
            bannerKey: true,
        },
        {
            image: image2,
            titleContent: "STR_GEN_INFINITI",
            titleContent1: "STR_AN",
            titleContent2: "STR_AN_OMNI_CHANNEL",
            titleContent3: "STR_AN_OMNI_CHANNEL_1",
            titleContent4: "STR_AN_OMNI_CHANNEL_2",
            titleContent5: "STR_AN_OMNI_CHANNEL_3",
            alt: "Group of POS lottery machines and mobile devices with a few lottery tickets flying and a man riding a racing horse",
            crouselClass: "hero-images sk1",
            contentDivClass: "hero-content1",
            bannerKey: true,
        },
        {
            image: image3,
            titleContent: "STR_MOST_EXP",
            titleContent1: "STR_RECOGNISED_VALUABLE",
            titleContent2: "STR_RECOGNISED_VALUABLE_1",
            alt: "Group of logos of lottery associations that skilrock is a member",
            crouselClass: "hero-images sk2",
            contentDivClass: "hero-content2",
            bannerKey: true,
        },
    ],
};
export { banner };
